/* -------------------------------------------------------------------------------- */
/*	7. Post Content
/* -------------------------------------------------------------------------------- */

.post-content {
  font-family: "Lato", sans-serif;
  font-size: 1.05em;
}

.post-content a:hover {
  text-decoration: underline;
}

.post-content p,
.post-content blockquote,
.post-content ul,
.post-content ol,
.post-content address,
.post-content dl,
.post-content pre {
  line-height: 170%;
  margin-bottom: 1.5em;
}

.post-content *:last-child {
  margin-bottom: 0;
}

.post-content h1,
.post-content h2,
.post-content h3,
.post-content h4,
.post-content h5,
.post-content h6 {
  margin: 50px 0 25px;
  font-family: "Playfair Display", Georgia, serif;
  line-height: 110%;
  font-weight: 700;
}

.post-content h1 {
  font-size: 2em;
}

.post-content h2 {
  font-size: 1.75em;
}

.post-content h3 {
  font-size: 1.5em;
}

.post-content h4 {
  font-size: 1.25em;
}

.post-content h5 {
  font-size: 1.1em;
}

.post-content h6 {
  font-family: "Lato", Helvetica, sans-serif;
  font-size: 0.8rem;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.post-content h1 + h1,
.post-content h1 + h2,
.post-content h1 + h3,
.post-content h1 + h4,
.post-content h1 + h5,
.post-content h1 + h6,
.post-content h2 + h1,
.post-content h2 + h2,
.post-content h2 + h3,
.post-content h2 + h4,
.post-content h2 + h5,
.post-content h2 + h6,
.post-content h3 + h1,
.post-content h3 + h2,
.post-content h3 + h3,
.post-content h3 + h4,
.post-content h3 + h5,
.post-content h3 + h6,
.post-content h4 + h1,
.post-content h4 + h2,
.post-content h4 + h3,
.post-content h4 + h4,
.post-content h4 + h5,
.post-content h4 + h6,
.post-content h5 + h1,
.post-content h5 + h2,
.post-content h5 + h3,
.post-content h5 + h4,
.post-content h5 + h5,
.post-content h5 + h6,
.post-content h6 + h1,
.post-content h6 + h2,
.post-content h6 + h3,
.post-content h6 + h4,
.post-content h6 + h5,
.post-content h6 + h6 {
  margin-top: 25px;
}

.post-content .more-link {
  display: inline-block;
  padding: 15px 18px;
  margin: 0;
  border: 2px solid #111;
  font-family: "Lato", Helvetica, sans-serif;
  font-size: 0.8rem;
  line-height: 1;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #111;
  -webkit-appearance: none;
}

.post-content .more-link:hover {
  background-color: #111;
  color: #fff;
  text-decoration: none;
}

.post-content blockquote {
  padding: 30px 30px 30px 90px;
  background: #fafafa;
  border: 1px solid #eee;
  position: relative;
  font-size: 0.95em;
  font-style: italic;
}

.post-content blockquote p {
  font-style: italic;
}

.post-content blockquote:after {
  content: "”";
  width: 90px;
  font-family: "Georgia", serif;
  font-size: 72px;
  line-height: 72px;
  font-weight: 700;
  font-style: normal;
  text-align: center;
  color: #ca2017;
  position: absolute;
  top: 31px;
  left: 0px;
}

.post-content blockquote cite {
  display: block;
  margin-top: 1.1em;
  font-family: "Lato", Helvetica, sans-serif;
  font-size: 0.75em;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.post-content blockquote cite:before {
  content: "— ";
}

.post-content hr {
  width: 128px;
  max-width: 60%;
  height: 2px;
  background: #ddd;
  margin: 50px auto;
  border: 0;
}

em,
q {
  font-style: italic;
}

.post-content strong em,
.post-content em strong {
  font-weight: bold;
  font-style: italic;
}

.post-content big {
  font-size: 1.25em;
}

abbr,
acronym {
  cursor: help;
}

code,
kbd,
pre {
  font-size: 0.85em;
  background: #eee;
  font-family: Menlo, Monaco, monospace;
}

.post-content .highlight {
  background: #fcf8a5;
  width: auto;
  display: inline;
  padding: 2px 3px;
}

.post-content kbd,
.post-content code {
  padding: 5px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.post-content dl {
  line-height: 160%;
}

.post-content dl dt {
  font-weight: bold;
}

.post-content ul,
.post-content ol {
  margin-left: 1.5em;
}

.post-content ul {
  list-style: disc;
}
.post-content ul ul {
  list-style: circle;
}
.post-content ul ul ul {
  list-style: square;
}

.post-content ol {
  list-style: decimal;
}
.post-content ol ol {
  list-style: lower-alpha;
}
.post-content ol ol ol {
  list-style: lower-roman;
}

.post-content ul ul,
.post-content ul ol,
.post-content ol ul,
.post-content ol ol {
  margin-bottom: 0;
}

.post-content li {
  margin-bottom: 0.5em;
  line-height: 170%;
}

.post-content ol > li:last-child,
.post-content ul > li:last-child {
  margin-bottom: 0;
}

.post-content ol > li:first-child,
.post-content ul > li:first-child {
  margin-top: 0.5em;
}

.post-content address {
  padding: 3% 3.5%;
  background: #f1f1f1;
}

.post-content pre {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  line-height: 140%;
  padding: 2% 2.5%;
  background: #eee;
  border: 1px solid #ddd;
}

/* Post Media --------------------------------------- */

.post-content img {
  padding: 5px;
  border: 1px solid #ddd;
}

.post-content .wp-caption {
  max-width: 100%;
}

.post-content .alignleft,
.post-content .alignright {
  margin-bottom: 1.2em;
  max-width: 50%;
}

.post-content .wp-caption .alignleft,
.post-content .wp-caption .alignright {
  margin-bottom: 0;
}

.post-content .alignleft {
  float: left;
  margin-right: 30px;
}

.post-content .alignright {
  float: right;
  margin-left: 30px;
}

.post-content .aligncenter,
.post-content .aligncenter img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.post-content .wp-caption-text,
.post-content .gallery-caption {
  margin: 4px auto 16px;
  font-family: "Lato", Helvetica, sans-serif;
  font-size: 0.9rem;
  line-height: 160%;
  color: #444;
  text-align: center;
}

.post-content .gallery-caption {
  font-size: 16px;
  line-height: 24px;
}

/* Tables --------------------------------------- */

.post-content table {
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  font-size: 0.9em;
  width: 100%;
  margin-bottom: 1.1em;
}

.post-content th,
.post-content td {
  padding: 2%;
  margin: 0;
  overflow: visible;
  line-height: 120%;
  border-bottom: 1px solid #ddd;
}

.post-content caption {
  color: #111;
  text-align: center;
  padding: 2%;
}

.post-content thead {
  vertical-align: bottom;
  white-space: nowrap;
}

.post-content th {
  font-weight: bold;
  color: #111;
}

.post-content table tbody > tr:nth-child(odd) > td {
  background: #f9f9f9;
}

/* Forms --------------------------------------- */

.post-content fieldset {
  padding: 25px;
  border: 2px solid #ddd;
  margin-bottom: 1em;
}

.post-content fieldset legend {
  font-family: "Lato", Helvetica, sans-serif;
  font-size: 0.8rem;
  line-height: 1;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 10px 12px;
  background: #111;
  color: #fff;
}

.post-content label {
  font-size: 1rem;
  font-family: "Lato", Helvetica, sans-serif;
  font-weight: 700;
}

.post-content input,
.post-content textarea {
  font-family: "Lato", Helvetica, sans-serif;
}

.post-content input[type="text"],
.post-content input[type="tel"],
.post-content input[type="url"],
.post-content input[type="email"],
.post-content input[type="password"],
.post-content textarea {
  width: 100%;
  padding: 20px;
  border: 1px solid #ddd;
  font-size: 0.9em;
  color: #111;
  background: #fafafa;
  border-radius: 0;
  -webkit-appearance: none;
}

.post-content input[type="file"] {
  padding: 10px;
  background: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
}

.post-content textarea {
  height: 180px;
  line-height: 140%;
}

.post-content input[type="text"]:focus,
.post-content input[type="tel"]:focus,
.post-content input[type="url"]:focus,
.post-content input[type="email"]:focus,
.post-content input[type="password"]:focus,
.post-content textarea:focus {
  outline: none;
}

.post-content input[type="submit"],
.post-content input[type="reset"],
.post-content input[type="button"] {
  padding: 16px 20px;
  margin: 0;
  border: none;
  border-radius: 3px;
  background: #111;
  font-size: 0.8rem;
  line-height: 1;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #fff;
  -webkit-appearance: none;
}

.post-content input[type="submit"] + input[type="reset"],
.post-content input[type="submit"] + input[type="button"],
.post-content input[type="reset"] + input[type="submit"],
.post-content input[type="reset"] + input[type="button"],
.post-content input[type="button"] + input[type="reset"],
.post-content input[type="submit"] + input[type="submit"] {
  margin-left: 5px;
}

.post-content input[type="submit"]:hover,
.post-content input[type="reset"]:hover,
.post-content input[type="button"]:hover {
  cursor: pointer;
  background: #ca2017;
  color: #fff;
}

.post-content input[type="submit"]:focus,
.post-content input[type="submit"]:active {
  outline: none;
}
