@charset "UTF-8";

$theme-color-1: #ebeced;
$theme-color-2: #cbc5c1;
$theme-color-3: #a2aab0;
$theme-color-4: #4c586f;
$theme-color-5: #3e3e3b;

@import "reset";
@import "document";
@import "structure";
@import "header";
@import "blog";
@import "single_post";
@import "post";
@import "comments";
@import "page";
@import "pagination";
@import "sidebar";
@import "footer";
@import "credits";
@import "media";

@import url('https://fonts.googleapis.com/css?family=Lato|Playfair+Display:400,700&display=swap');

$old-links:  #ca2017;

/* social sharing buttons */
.social-links {
  a {
    font-size: 42px;
    margin: 5px;
    display: inline-block;
  }
  .fa-facebook {
    color: #3b5998;
  }
  .fa-twitter-square {
    color: #55acee;
  }
  .fa-linkedin {
    color: #0073b1;
  }
  .fa-google-plus-square {
    color: #dc4e41;
  }
  .fa-pinterest-square {
    color: #bd081c;
  }
  .fa-envelope-square {
    color: #666;
  }
}
