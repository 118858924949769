/* -------------------------------------------------------------------------------- */
/*	8. Comments
/* -------------------------------------------------------------------------------- */

.comments-container {
  border-top: 1px solid #ddd;
}

.comments-inner {
  width: 629px;
  max-width: 85%;
  padding: 60px 0;
  margin: 0 auto;
  position: relative;
}

.comments-title-container {
  position: relative;
}

.comments-title-container,
.comment-reply-title {
  padding-bottom: 25px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 25px;
  vertical-align: bottom;
}

.comments-title,
.comment-reply-title {
  font-size: 2em;
  font-weight: 700;
}

.comments-title-link {
  font-family: "Lato", Helvetica, sans-serif;
  font-size: 0.75em;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: absolute;
  bottom: 26px;
  right: 0;
}

.comments-title-link a {
  display: block;
  color: #ca2017;
}

.comments-title-link a:hover {
  text-decoration: underline;
}

.no-comments {
  text-align: center;
}

/* Comment List ---------------------------------------- */

.comment {
  position: relative;
}

div.comment {
  margin-top: 25px;
}

.commentlist > .comment:first-child > .comment:first-child {
  margin-top: 0;
}

.commentlist .children {
  margin-left: 25px;
}

.comment .avatar {
  display: block;
  width: 60px;
  height: auto;
  border-radius: 999px;
  position: absolute;
  top: 10px;
  left: 0;
}

.comment-inner {
  margin-left: 80px;
  padding: 25px;
  background: #f1f1f1;
  border-radius: 6px;
  position: relative;
}

.bypostauthor .comment-author-icon {
  display: block;
  padding: 4px;
  border-radius: 99px;
  background: #ca2017;
  text-align: center;
  color: #fff;
  position: absolute;
  top: 4px;
  left: -4px;
}

.comment-inner:before {
  content: "";
  display: block;
  border: 10px solid transparent;
  border-right-color: #f1f1f1;
  position: absolute;
  top: 30px;
  left: -20px;
}

.comment-header {
  margin-bottom: 20px;
}

.comment-header h4 {
  font-size: 1.1em;
  font-weight: 700;
}

.comment-header h4 a {
  color: #111;
}
.comment-header h4 a:hover {
  color: #ca2017;
}

/* Comment Content */

.comment-content p,
.comment-content blockquote,
.comment-content ul,
.comment-content ol,
.comment-content address,
.comment-content dl,
.comment-content pre {
  line-height: 160%;
  margin-bottom: 1em;
}

/* Comment Meta */

.comment-meta {
  margin-top: 23px;
  font-family: "Lato", Helvetica, sans-serif;
  font-size: 0.7em;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #777;
}

.comment-meta a {
  color: #777;
}

.comment-meta .genericon {
  margin-right: 3px;
  position: relative;
  top: -2px;
}

.comment-meta .genericon-edit {
  margin-left: 12px;
}

/* Pingbacks & Tracksbacks ----------------------------------- */

.pingbacks {
  margin-top: 40px;
}

.pingbacks-title {
  display: inline-block;
  padding-bottom: 2px;
  margin-bottom: 20px;
  border-bottom: 2px solid #ca2017;
  font-family: "Lato", Helvetica, sans-serif;
  font-size: 0.75em;
  font-weight: 900;
  color: #111;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.pingbacklist li {
  line-height: 125%;
}

.pingbacklist li + li {
  margin-top: 10px;
  border-top: 1px solid #ddd;
  padding-top: 10px;
}

.pingbacklist a {
  color: #111;
}
.pingbacklist a:hover {
  color: #ca2017;
}

/* Comment Navigation ---------------------------------------- */

.comments-nav {
  margin-top: 30px;
}

.comments-nav a {
  font-family: "Lato", Helvetica, sans-serif;
  font-size: 0.9em;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #666;
}

.comments-nav a:hover {
  color: #ca2017;
}

/* -------------------------------------------------------------------------------- */
/*	9. Respond
/* -------------------------------------------------------------------------------- */

.respond-container {
  border-top: 1px solid #ddd;
}

.comment-respond {
  width: 629px;
  max-width: 85%;
  padding: 60px 0;
  margin: 0 auto;
}

.comment-respond .logged-in-as {
  margin-bottom: 40px;
}

.comment-respond p a:hover {
  text-decoration: underline;
}

.comment-respond form label {
  display: block;
  margin-bottom: 8px;
  font-family: "Lato", Helvetica, sans-serif;
  font-size: 0.8rem;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #111;
}

.comment-respond form label span {
  margin-left: 2px;
  color: #ca2017;
}

.comment-respond p {
  margin-bottom: 25px;
}
.comment-respond p:last-child {
  margin-bottom: 0;
}

.comment-respond p.comment-subscription-form {
  margin-bottom: 10px;
}
.comment-respond p.comment-subscription-form:last-of-type {
  margin-bottom: 0;
}

.comment-form-author,
.comment-form-email {
  display: block;
  width: 47.5%;
  float: left;
}

.comment-form-email {
  float: right;
}

.comment-respond input,
.comment-respond textarea {
  border-radius: 0;
  -webkit-appearance: none;
}

.comment-respond input[type="text"],
.comment-respond textarea {
  display: block;
  width: 100%;
  padding: 20px;
  margin: 0;
  background: #fafafa;
  border: 1px solid #ddd;
  font-family: "Lato", Helvetica, sans-serif;
  font-size: 1rem;
  color: #111;
}

.comment-respond input[type="text"]:focus,
.comment-respond textarea:focus {
  outline: none;
}

.comment-respond textarea {
  height: 200px;
  line-height: 140%;
}

.comment-respond input[type="submit"] {
  display: inline-block;
  padding: 16px 20px;
  margin: 0;
  background: #111;
  border: none;
  border-radius: 3px;
  font-family: "Lato", Helvetica, sans-serif;
  font-size: 0.8rem;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #fff;
  -webkit-appearance: none;
}

.comment-respond input[type="submit"]:hover {
  cursor: pointer;
  background: #ca2017;
  color: #fff;
}

/* In Comment List --------------------------------------- */

.commentlist .comment-respond {
  padding: 40px 0 15px;
  max-width: 100%;
}

.commentlist #cancel-comment-reply-link {
  margin-left: 3px;
  font-family: "Lato", Helvetica, sans-serif;
  font-size: 0.8rem;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;
}

.commentlist #cancel-comment-reply-link:hover {
  text-decoration: underline;
}
