/* -------------------------------------------------------------------------------- */
/*	5. Blog
/* -------------------------------------------------------------------------------- */

.content {
  width: 68.7%;
  float: left;
  margin-top: -40px;
}

.post {
  display: block;
  background: #fff;
  border: 1px solid #ddd;
  position: relative;
}

.post + .post,
.infinite-wrap .post:first-child {
  margin-top: 30px;
}

/* Sticky Post --------------------------------------- */

.sticky-post {
  display: block;
  box-sizing: border-box;
  height: 40px;
  width: 30px;
  background: #ca2017;
  text-align: center;
  color: #fff;
  position: absolute;
  top: -1px;
  right: 3.75%;
}

.sticky-post .genericon {
  position: absolute;
  bottom: 12px;
  left: 50%;
  margin-left: -8px;
}

.sticky-post:after {
  content: "";
  border: 15px solid transparent;
  border-top: none;
  border-bottom-width: 8px;
  border-left-color: #ca2017;
  border-right-color: #ca2017;
  position: absolute;
  bottom: -8px;
  right: 0;
  -webkit-transform: scale(1.0001);
  -moz-transform: scale(1.0001);
}

.sticky-post:hover {
  height: 43px;
  color: #fff;
}

/* Post Image --------------------------------------- */

.post-image,
.post-image img {
  display: block;
}

/* Post Inner --------------------------------------- */

.post-inner {
  width: 629px;
  max-width: 85%;
  padding: 60px 0;
  margin: 0 auto;
}

/* Post Header --------------------------------------- */

.post-header + .post-content {
  margin-top: 50px;
}

.post-title {
  font-family: "Playfair Display", Georgia, serif;
  font-size: 2.6em;
  line-height: 130%;
  font-weight: 700;
  color: #111;
}

.post-title a {
  color: #111;
}
.post-title a:hover {
  color: #111;
  text-decoration: underline;
}

/* Post Meta */

.post-meta {
  margin-top: 20px;
  border-top: 1px solid #ddd;
  padding-top: 20px;
}

.post-meta p {
  display: inline-block;
  font-family: "Lato", Helvetica, sans-serif;
  font-size: 0.75em;
  line-height: 200%;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #666;
}

.post-meta p + p:before {
  content: "/";
  margin: 0 15px 0 11px;
  color: #ccc;
}

.post-meta a {
  color: #ca2017;
  padding-bottom: 1px;
  border-bottom: 1px solid #ddd;
}

.post-meta a:hover {
  border-bottom-color: #ca2017;
}
