/* -------------------------------------------------------------------------------- */
/*	6. Single Post
/* -------------------------------------------------------------------------------- */

/* Post Links -------------------------------------------- */

.post-content p.page-links {
  display: inline-block;
  margin-bottom: 15px;
  background: #eee;
  font-family: "Lato", Helvetica, sans-serif;
  font-size: 0.75em;
  line-height: 1;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #666;
}

.page-links a,
.page-links > span {
  display: inline-block;
  padding: 10px;
}

.page-links a:first-child,
.page-links span:first-child {
  margin-left: 0;
}

.post-content .page-links a:hover {
  background: #ca2017;
  color: #fff;
  text-decoration: none;
}

.page-links > span:nth-of-type(2) {
  background: #ccc;
  color: #111;
}

/* Post Tags --------------------------------------------- */

.post-tags {
  margin-top: 60px;
}

.post-tags,
.tagcloud {
  line-height: 1;
}

.post-tags a,
.tagcloud a {
  display: inline-block;
  padding: 5px 6px 6px 3px;
  margin: 0 3px 8px 11px;
  background: #ddd;
  font-family: "Lato", Helvetica, sans-serif;
  font-size: 0.7rem !important;
  line-height: 1;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #444;
  position: relative;
}

.post-tags a {
  margin-right: 8px;
}

.footer .tagcloud a {
  background: #eee;
}
.footer .tagcloud a:before {
  border-right-color: #eee;
}

.post-tags a:before,
.tagcloud a:before {
  content: "";
  display: block;
  border: 11px solid transparent;
  border-left-width: 0;
  border-right-color: #ddd;
  position: absolute;
  top: 0;
  left: -11px;
}

.post-tags a:hover,
.tagcloud a:hover {
  background: #ca2017;
  color: #fff;
}

.post-tags a:hover:before,
.tagcloud a:hover:before {
  border-right-color: #ca2017;
}

/* Post Navigation --------------------------------------- */

.post-navigation {
  border-top: 1px solid #ddd;
  position: relative;
}

.post-navigation-inner {
  width: 629px;
  max-width: 85%;
  padding: 30px 0;
  margin: 0 auto;
}

.post-navigation:before {
  content: "";
  display: block;
  width: 1px;
  height: 100%;
  background: #ddd;
  position: absolute;
  left: 50%;
}

.post-nav-prev,
.post-nav-next {
  display: block;
  float: left;
  width: 47.5%;
}

.post-navigation p {
  display: inline-block;
  padding-bottom: 2px;
  border-bottom: 2px solid #ccc;
  margin-bottom: 8px;
  font-family: "Lato", Helvetica, sans-serif;
  font-size: 0.8rem;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #999;
}

.post-navigation h4 {
  display: block;
  font-size: 1em;
  line-height: 130%;
  font-weight: 700;
}

.post-navigation h4 a {
  color: #111;
}
.post-navigation h4 a:hover {
  color: #ca2017;
}

.post-nav-next {
  float: right;
}

.post-nav-next,
.post-nav-next h4 {
  text-align: right;
}
