/* -------------------------------------------------------------------------------- */
/*	13. Footer
/* -------------------------------------------------------------------------------- */

.footer {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.footer .widgets {
  width: 30%;
  float: left;
  margin-left: 5%;
}

.footer .widgets:first-child {
  margin-left: 0;
}
