/* -------------------------------------------------------------------------------- */
/*	10. Page & Page Templates
/* -------------------------------------------------------------------------------- */

/* Full Width Page Template -------------------------- */

.page-template-full-width-page-template-php .content {
  width: 100%;
  float: none;
}

.page-template-full-width-page-template-php .post-inner {
  width: auto;
}

/* Search Page --------------------------------------- */

.wrapper .search-form,
.footer .search-form {
  position: relative;
}

.wrapper .search-field,
.footer .search-field {
  display: block;
  width: 100%;
  padding: 20px 60px 20px 20px;
  margin: 0;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 0;
  font-family: "Lato", Helvetica, sans-serif;
  font-size: 0.9rem;
  color: #111;
}

.wrapper .search-field:focus,
.footer .search-field:focus {
  outline: none;
}

.wrapper .search-button,
.footer .search-button {
  display: block;
  height: 100%;
  width: 60px;
  background: none;
  border-left: 1px solid #ddd;
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
}

.wrapper .search-button .genericon,
.footer .search-button .genericon {
  font-size: 24px;
}

.wrapper .search-button:hover,
.footer .search-button:hover {
  cursor: pointer;
}

.wrapper .search-button:hover .genericon,
.footer .search-button:hover .genericon {
  color: #ca2017;
}
