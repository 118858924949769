/* -------------------------------------------------------------------------------- */
/*	1. Document setup
/* -------------------------------------------------------------------------------- */

body {
  margin: 0;
  padding: 0;
  border: none;
  color: #111;
  background: $theme-color-1;
  font-family: "Playfair Display", "Georgia", serif;
  font-size: 17px;
  -webkit-font-smoothing: antialiased;
}

body a {
  color: $theme-color-4;
  text-decoration: none;
}

body a:hover {
  color: $theme-color-4;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

img {
  max-width: 100%;
  height: auto;
}
img.profile {
  float: right;
  position: relative;
  top: -20px;
  right: -40px;

  @media (max-width: 400px) {
    float: none;
    clear: both;
  }
}

.hidden {
  display: none;
}
.clear {
  clear: both;
}
.fleft {
  float: left;
}
.fright {
  float: right;
}

::selection {
  background: #1d1d1d;
  color: #fff;
}

::-webkit-input-placeholder {
  color: #666;
}
:-ms-input-placeholder {
  color: #666;
}

/* Transitions --------------------------------------- */

body a,
.post-tags a,
.post-tags a:before,
.tagcloud a,
.tagcloud a:before {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -ms-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

.main-menu a,
.main-menu li::after,
.blog-title a,
.content form input,
.content form textarea,
.sticky-post,
.search-submit,
.more-link,
#infinite-handle span,
.main-menu > .menu-item-has-children a,
.main-menu > .menu-item-has-children > a::after {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.nav-toggle .bar {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

/* Screen Reader Text --------------------------------------- */

.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.screen-reader-text:focus {
  background-color: #f1f1f1;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  color: #21759b;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: bold;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000; /* Above WP toolbar. */
}
