/* -------------------------------------------------------------------------------- */
/*	0. CSS Reset
/* -------------------------------------------------------------------------------- */

html,
body {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
address,
big,
cite,
code,
em,
font,
img,
small,
strike,
sub,
sup,
li,
ol,
ul,
fieldset,
form,
label,
legend,
button,
table,
caption,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  font-weight: normal;
  font-style: normal;
  font-size: 100%;
  line-height: 1;
  font-family: inherit;
  text-align: left;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

ol,
ul {
  list-style: none;
}

blockquote:before,
blockquote:after {
  content: "";
}

a {
  outline: none;
}

input,
textarea {
  -webkit-font-smoothing: antialiased;
}

input[type="search"] {
  -moz-appearance: none;
  -webkit-appearance: none;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

* {
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}
