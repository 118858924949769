/* -------------------------------------------------------------------------------- */
/*	15. Media Queries
/* -------------------------------------------------------------------------------- */

@media (max-width: 1000px) {
  /* Structure --------------------------------------- */

  .content {
    width: 100%;
    float: none;
  }

  .sidebar {
    display: none;
  }

  /* Navigation --------------------------------------- */

  .navigation > .section-inner {
    max-width: 100%;
  }

  /* Nav Toggle */

  .toggles {
    display: block;
    background: #fff;
    border-top: 1px solid #eee;
    padding: 0 5%;
  }

  .toggle {
    display: block;
    width: 30px;
    height: 55px;
    position: relative;
  }

  .toggle:hover {
    cursor: pointer;
  }

  .nav-toggle {
    float: left;
  }

  .nav-toggle .bar {
    width: 30px;
    height: 3px;
    background: #111;
    border-radius: 2px;
    position: absolute;
    left: 50%;
    margin-left: -15px;
    top: 50%;
    -webkit-transition: opacity 0.2s linear 0.2s, margin 0.2s linear 0.2s, -webkit-transform 0.2s linear 0s;
    -moz-transition: opacity 0.2s linear 0.2s, margin 0.2s linear 0.2s, -moz-transform 0.2s linear 0s;
    -o-transition: opacity 0.2s linear 0.2s, margin 0.2s linear 0.2s, -o-transform 0.2s linear 0s;
    transition: opacity 0.2s linear 0.2s, margin 0.2s linear 0.2s, transform 0.2s linear 0s;
  }

  .nav-toggle .bar:nth-child(1) {
    margin-top: -10px;
  }
  .nav-toggle .bar:nth-child(2) {
    margin-top: -2px;
  }
  .nav-toggle .bar:nth-child(3) {
    margin-top: 6px;
  }

  .nav-toggle.active .bar {
    background: #ca2017;
    -webkit-transition: opacity 0.2s linear 0s, margin 0.2s linear 0s, -webkit-transform 0.2s linear 0.2s;
    -moz-transition: opacity 0.2s linear 0s, margin 0.2s linear 0s, -moz-transform 0.2s linear 0.2s;
    -o-transition: opacity 0.2s linear 0s, margin 0.2s linear 0s, -o-transform 0.2s linear 0.2s;
    transition: opacity 0.2s linear 0s, margin 0.2s linear 0s, transform 0.2s linear 0.2s;
  }

  .nav-toggle.active .bar:nth-child(1),
  .nav-toggle.active .bar:nth-child(3) {
    margin-top: -2px;
  }

  .nav-toggle.active .bar:nth-child(1) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .nav-toggle.active .bar:nth-child(2) {
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }

  .nav-toggle.active .bar:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  /* Search Toggle */

  .search-toggle {
    float: right;
  }

  .search-toggle .genericon {
    width: 100%;
    font-size: 32px;
    text-align: center;
    position: absolute;
    top: 50%;
    margin-top: -16px;
  }

  .search-toggle.active {
    color: #ca2017;
  }

  /* Menus --------------------------------------- */

  .main-menu {
    display: none;
  }

  .mobile-menu li {
    border-top: 1px solid #eee;
  }

  .mobile-menu > li:first-child {
    border-top: none;
  }

  .mobile-menu a {
    display: block;
    padding: 20px 5%;
    font-size: 0.9em;
    color: #111;
  }

  .mobile-menu ul a {
    padding-left: 10%;
  }
  .mobile-menu ul ul a {
    padding-left: 15%;
  }
  .mobile-menu ul ul ul a {
    padding-left: 20%;
  }
  .mobile-menu ul ul ul ul a {
    padding-left: 25%;
  }
  .mobile-menu ul ul ul ul ul a {
    padding-left: 30%;
  }

  /* Mobile Search --------------------------------------- */

  .mobile-search {
    position: relative;
  }

  .mobile-search form {
    display: block;
    max-width: 90%;
    padding: 40px 0;
    margin: 0 auto;
    position: relative;
  }

  .mobile-search .search-field {
    display: block;
    width: 100%;
    padding: 20px 84px 20px 20px;
    margin: 0;
    background: #fff;
    border: 1px solid #eee;
    border-radius: 0;
    font-family: "Lato", Helvetica, sans-serif;
    font-size: 0.9rem;
  }

  .mobile-search .search-field:focus {
    outline: none;
  }

  .mobile-search .search-button {
    display: block;
    width: 59px;
    height: 59px;
    margin: 0;
    padding: 0;
    background: none;
    border: none;
    border-left: 1px solid #eee;
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -30px;
  }

  .mobile-search .search-button .genericon {
    display: block;
    font-size: 32px;
    text-align: center;
  }

  .mobile-search .search-button:hover {
    color: #ca2017;
    cursor: pointer;
  }
}

@media (max-width: 600px) {
  /* Structure --------------------------------------- */

  .section {
    padding: 40px 0;
  }
  .big-padding {
    padding: 50px 0;
  }
  .medium-padding {
    padding: 30px 0;
  }
  .small-padding {
    padding: 20px 0;
  }

  /* Header --------------------------------------- */

  .header-wrapper {
    position: relative;
  }

  .header {
    padding: 10px 0 13px;
  }

  .header .section-inner {
    max-width: 100%;
  }

  .blog-title {
    font-size: 1.5em;
    padding: 0 24px;
    margin: 0 10%;
  }

  .blog-tagline {
    display: none;
  }

  /* Navigation --------------------------------------- */

  .navigation {
    border-top: none;
  }

  .toggles {
    background: none;
    border: none;
  }

  .toggle {
    height: 54px;
    width: 24px;
    position: absolute;
    top: 50%;
    margin-top: -27px;
  }

  .nav-toggle {
    left: 5%;
  }
  .search-toggle {
    right: 5%;
  }

  .nav-toggle .bar {
    width: 24px;
    margin-left: -12px;
  }

  .nav-toggle .bar:nth-child(1) {
    margin-top: -9px;
  }
  .nav-toggle .bar:nth-child(3) {
    margin-top: 5px;
  }

  .search-toggle .genericon {
    font-size: 30px;
    margin-top: -15px;
  }

  .mobile-menu > li:first-child {
    border-top: 1px solid #eee;
  }

  .mobile-search {
    border-top: 1px solid #eee;
  }

  .mobile-search form {
    padding: 5% 0;
  }

  /* Content --------------------------------------- */

  .content {
    margin-top: -80px;
  }

  .post + .post,
  .infinite-wrap .post:first-child {
    margin-top: 40px;
  }

  .post-inner {
    max-width: 100%;
    padding: 7.5%;
  }

  .post-header + .post-content {
    margin-top: 30px;
  }

  .post-title {
    font-size: 2.25em;
    line-height: 125%;
  }

  .post-meta {
    padding-top: 12px;
    margin-top: 12px;
  }

  .post-meta p:nth-child(3) {
    display: none;
  }

  /* Post Content --------------------------------------- */

  .post-content blockquote {
    padding: 20px 20px 20px 70px;
  }

  .post-content blockquote:after {
    font-size: 64px;
    width: 70px;
    top: 18px;
  }

  /* Comments --------------------------------------- */

  .comments-title-container,
  .comment-reply-title {
    padding-bottom: 15px;
    margin-bottom: 15px;
  }

  .comments-title,
  .comment-reply-title {
    font-size: 1.5em;
  }

  .comments-title-link {
    display: none;
  }

  .comment .avatar {
    display: none;
  }

  .bypostauthor .comment-author-icon {
    top: -8px;
    left: -8px;
    z-index: 200;
  }

  .comment-inner {
    margin-left: 0;
  }
  .comment-inner:before {
    content: none;
  }

  .comments-nav {
    margin-top: 20px;
  }

  /* Respond --------------------------------------- */

  .comment-form-author,
  .comment-form-email {
    width: 100%;
    float: none;
  }

  /* Pagination --------------------------------------- */

  .page-title {
    height: 40px;
    padding-top: 13px;
  }

  .archive-navigation,
  #infinite-handle,
  #infinite-loader {
    margin-top: 40px;
  }

  /* Footer --------------------------------------- */

  .footer .widgets {
    width: 100%;
    float: none;
  }

  .footer .widgets + .widgets {
    margin: 40px 0 0;
    border-top: 1px solid #ddd;
    padding: 40px 0 0;
  }
}

@media (max-width: 500px) {
  /* Content --------------------------------------- */

  .wrapper {
    padding: 0;
  }

  .wrapper .section-inner {
    max-width: 100%;
  }

  .content {
    margin-top: 0;
  }

  .post {
    border: none;
  }

  .post + .post {
    margin-top: 0;
    border-top: 2px solid #eee;
  }

  .sticky-post {
    width: 20px;
    height: 24px;
    top: 0;
    right: 2.5%;
  }

  .sticky-post .genericon {
    bottom: 5px;
  }

  .sticky-post:after {
    border-right-width: 10px;
    border-bottom-width: 5px;
    border-left-width: 10px;
    bottom: -5px;
  }

  .sticky-post:hover {
    height: 24px;
  }

  .post-image {
    padding: 7.5% 5% 0 5%;
  }

  .post-inner {
    padding: 7.5% 5%;
  }

  .post-image + .post-inner {
    padding-top: 7%;
  }

  .post-title {
    font-size: 1.75em;
  }

  .post-meta {
    margin-top: 10px;
    padding-top: 7px;
  }

  .post-meta p {
    font-size: 0.7em;
  }

  .post-meta span {
    display: none;
  }

  .post-meta p + p:before {
    margin: 0 10px 0 6px;
  }

  .post-content .more-link {
    padding: 13px 15px;
    font-size: 0.75rem;
  }

  .post-header + .post-content {
    margin-top: 7%;
  }

  /* Single Post --------------------------------------- */

  .single .wrapper {
    padding-bottom: 0;
  }

  .post-tags {
    margin-top: 30px;
  }

  .post-navigation:before {
    content: none;
  }

  .post-navigation-inner {
    max-width: 100%;
    padding: 0;
    margin: 0;
  }

  .post-nav-prev,
  .post-nav-next {
    float: none;
    width: 100%;
    padding: 20px 5%;
  }

  .post-nav-prev + .post-nav-next {
    border-top: 1px solid #ddd;
  }

  /* Post Content --------------------------------------- */

  .post-content h1,
  .post-content h2,
  .post-content h3,
  .post-content h4,
  .post-content h5,
  .post-content h6 {
    margin: 40px 0 20px;
  }

  .post-content h1 {
    font-size: 1.5em;
  }
  .post-content h2 {
    font-size: 1.4em;
  }
  .post-content h3 {
    font-size: 1.3em;
  }
  .post-content h4 {
    font-size: 1.2em;
  }
  .post-content h5 {
    font-size: 1.1em;
  }

  .post-content hr {
    margin: 40px auto;
  }

  .post-content .alignleft,
  .post-content .alignright {
    display: block;
    float: none;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .post-content .alignleft img,
  .post-content .alignright img {
    display: block;
    margin: 0 auto;
  }

  .post-content input[type="text"],
  .post-content input[type="tel"],
  .post-content input[type="url"],
  .post-content input[type="email"],
  .post-content input[type="password"],
  .post-content textarea {
    padding: 16px;
  }

  /* Comments --------------------------------------- */

  .comments-inner,
  .comment-respond {
    max-width: 90%;
    padding: 40px 0;
  }

  div.comment {
    margin-top: 20px;
  }

  .commentlist .children {
    margin-left: 20px;
  }

  .comment-inner {
    padding: 20px;
    border-radius: 3px;
  }

  .comment-header {
    margin-bottom: 20px;
  }

  .comment-content {
    font-size: 0.95em;
  }

  .comments-nav a {
    font-size: 0.8em;
  }

  /* Respond --------------------------------------- */

  .comment-respond p {
    margin-bottom: 15px;
  }

  .comment-respond input[type="text"],
  .comment-respond textarea {
    padding: 15px;
  }

  .comment-respond textarea {
    height: 150px;
  }

  .comment-respond input[type="submit"] {
    padding: 13px 15px;
  }

  /* Page & Page Templates --------------------------------------- */

  .single-attachment .featured-image {
    padding: 5%;
  }

  /* Pagination --------------------------------------- */

  .page-title {
    height: auto;
    padding: 20px 0;
    background: #fff;
    border-bottom: 1px solid #eee;
  }

  .page-title h4 {
    color: #111;
    text-shadow: none;
  }

  .page-title h4 span {
    color: #666;
  }

  .archive-navigation {
    max-width: 100%;
    padding: 25px 5% 28px;
    margin: 0;
    border-top: 1px solid #ddd;
    text-align: center;
    background: #fff;
  }

  .archive-navigation .fleft {
    display: none;
  }

  .archive-navigation .fright {
    float: none;
  }

  .archive-navigation p {
    font-size: 0.8em;
  }

  .archive-navigation p + p:before {
    margin: 0 12px;
  }

  /* Footer --------------------------------------- */

  .footer {
    background: #fafafa;
    border-top-color: #ddd;
    padding: 10% 0;
  }

  .footer .widgets + .widgets,
  .widget + .widget {
    padding-top: 11%;
    margin-top: 11%;
  }

  .footer .tagcloud a {
    background: #ddd;
  }
  .footer .tagcloud a:before {
    border-right-color: #ddd;
  }

  .footer .tagcloud a:hover {
    background: #ca2017;
  }
  .footer .tagcloud a:hover:before {
    border-right-color: #ca2017;
  }
}

@media (max-width: 440px) {
  /* Single Post --------------------------------------- */

  .post-title {
    font-size: 1.5em;
  }

  .post-meta {
    margin-top: 8px;
    padding-top: 5px;
  }

  /* Post Content ---------------------------------- */

  .post-content blockquote {
    padding: 20px;
  }

  .post-content blockquote:after {
    display: none;
  }

  /* Comments -------------------------------------- */

  .comments-title,
  .comment-reply-title {
    font-size: 1.3em;
  }

  .comment-inner {
    padding: 15px;
  }

  .comment-header {
    margin-bottom: 15px;
  }

  .comment-meta {
    margin-top: 16px;
    font-size: 0.65em;
  }

  /* Credits --------------------------------------- */

  .credits p {
    padding-bottom: 0;
  }

  .credits p span {
    display: none;
  }

  .credits p a:hover {
    border-bottom-color: #ca2017;
  }
}
