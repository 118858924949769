/* -------------------------------------------------------------------------------- */
/*	2. Structure
/* -------------------------------------------------------------------------------- */

.section {
  padding: 60px 0;
  display: block;
}

.big-padding {
  padding: 80px 0;
}
.medium-padding {
  padding: 40px 0;
}
.small-padding {
  padding: 20px 0;
}
.no-padding {
  padding: 0;
}

.bg-white {
  background: #fff;
}
.bg-light {
  background-color: #fafafa;
}
.bg-red {
  background-color: #ca2017;
}
.bg-graphite {
  background-color: #222;
}
.bg-dark {
  background-color: #111;
}

.bg-image {
  -webkit-background-size: 100%;
  -moz-background-size: 100%;
  -o-background-size: 100%;
  background-size: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
}

.section-inner {
  display: block;
  width: 1140px;
  max-width: 90%;
  margin: 0 auto;
  z-index: 2;
}

.section-inner.light {
  width: 900px;
}
.section-inner.thin {
  width: 700px;
}

.wrapper-inner {
  position: relative;
}

/* gridiriffic */

.one-fourth {
  width: 22%;
  margin-left: 4%;
  float: left;
}
.one-third {
  width: 30%;
  margin-left: 5%;
  float: left;
}
.one-half {
  width: 47.5%;
  margin-left: 5%;
  float: left;
}
.two-thirds {
  width: 65%;
  margin-left: 5%;
  float: left;
}

.one-fourth:first-child,
.one-third:first-child,
.one-half:first-child,
.two-thirds:first-child {
  margin-left: 0;
}
