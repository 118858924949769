/* -------------------------------------------------------------------------------- */
/*	11. Pagination
/* -------------------------------------------------------------------------------- */

/* Page Title ---------------------------------------- */

.page-title {
  padding: 23px 0 0;
  height: 60px;
  background: rgba(0, 0, 0, 0.6);
}

.page-title h4 {
  font-family: "Lato", Helvetica, sans-serif;
  font-size: 0.75em;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #fff;
  text-align: center;
  text-shadow: 1px 0 0 rgba(0, 0, 0, 0.2);
}

.page-title span {
  color: rgba(255, 255, 255, 0.8);
}

.page-title + .posts > .post:first-child {
  border-top: none;
}

/* Archive Navigation --------------------------------------- */

.archive-navigation {
  margin-top: 60px;
}

.archive-navigation p {
  display: inline;
  font-family: "Lato", Helvetica, sans-serif;
  font-size: 0.9em;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.archive-navigation p + p:before {
  content: "/";
  display: inline;
  margin: 0 20px;
  color: #aaa;
}

.archive-navigation,
.archive-navigation a {
  color: #666;
}

.archive-navigation a:hover {
  color: #ca2017;
}

/* Infinite Scroll --------------------------------------- */

.infinite-scroll .archive-navigation {
  display: none;
}

#infinite-handle,
.infinite-loader {
  margin-top: 60px;
  text-align: center;
}

#infinite-handle span {
  display: inline-block;
  padding: 15px 20px;
  border-radius: 3px;
  background: #111;
  font-family: "Lato", Helvetica, sans-serif;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 1px;
}

#infinite-handle span:hover {
  cursor: pointer;
  background: #ca2017;
  color: #fff;
}

.infinite-loader .spinner {
  display: inline-block;
}

#infinite-handle span button {
  -webkit-font-smoothing: antialiased;
}
