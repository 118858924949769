/* -------------------------------------------------------------------------------- */
/*	12. Sidebar
/* -------------------------------------------------------------------------------- */

.sidebar {
  width: 26.3%;
  float: right;
}

.widget + .widget {
  padding-top: 40px;
  border-top: 1px solid #ddd;
  margin-top: 40px;
}

.widget-title {
  display: inline-block;
  padding-bottom: 3px;
  border-bottom: 2px solid $theme-color-4;
  margin-bottom: 20px;
  font-family: "Lato", Helvetica, sans-serif;
  font-size: 0.78em;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 1px;
}

/* Widget Content --------------------------------------- */

.widget-content {
  font-family: "Lato", Helvetica, sans-serif;
  font-size: 0.95em;
}

.widget-content .textwidget a:hover {
  text-decoration: underline;
}

.widget-content p {
  line-height: 150%;
  margin-top: 1em;
}

.widget-content p:first-child {
  margin-top: 0;
}

.widget-content li {
  line-height: 140%;
  padding: 12px 0;
  border-bottom: 1px solid #ddd;
}

.widget-content ul ul {
  margin-left: 1em;
}

.widget-content ul ul li:first-child {
  margin-top: 0.5em;
  border-top: 1px solid #ddd;
}

.widget-content > ul > li:first-child {
  padding-top: 0;
}

.widget-content li:last-child {
  padding-bottom: 0;
  border-bottom: none;
}

/* Text Widget --------------------------------------- */

.textwidget {
  line-height: 150%;
}

/* Widget Icons --------------------------------------- */

.widget_archive li,
.widget_categories li,
.widget_meta li,
.widget_nav_menu li,
.widget_pages {
  color: #888;
}

.widget_archive li a,
.widget_categories li a,
.widget_meta li a,
.widget_nav_menu li a,
.widget_pages li a {
  color: #111;
}

.widget_archive li a:hover,
.widget_categories li a:hover,
.widget_meta li a:hover,
.widget_nav_menu li a:hover,
.widget_pages li a:hover {
  color: #ca2017;
  text-decoration: none;
}

.widget_archive li:before,
.widget_categories li:before,
.widget_meta li:before,
.widget_nav_menu li:before {
  font: 16px/1 "Genericons";
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: middle;
  color: #999;
  margin-right: 8px;
  position: relative;
  top: -1px;
}

.widget_archive li:before {
  content: "\f307";
}
.widget_categories li:before {
  content: "\f301";
}
.widget_meta li:before {
  content: "\f445";
}
.widget_nav_menu li:before {
  content: "\f429";
}

/* RSS Widget --------------------------------------- */

.widget_rss .widget-title img {
  width: 13px;
  height: 13px;
  margin-right: 2px;
  position: relative;
  bottom: -2px;
}

.widget_rss li a {
  display: block;
  font-family: "Playfair Display", Georgia, serif;
  font-size: 1.1em;
  line-height: 120%;
  font-weight: 700;
  color: #111;
}

.widget_rss li .rss-date {
  display: block;
  margin-top: 2px;
  font-size: 0.8em;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #888;
}

.widget_rss li .rssSummary {
  margin-top: 5px;
}

.widget_rss li cite {
  display: block;
  margin-top: 8px;
  font-size: 0.9em;
}

.widget_rss li cite:before {
  content: "— ";
}

/* Widget Calendar --------------------------------------- */

#wp-calendar {
  width: 100%;
  max-width: 100%;
  color: #111;
  text-align: center;
}

#wp-calendar thead {
  border-top: 1px solid #ddd;
}

#wp-calendar,
#wp-calendar caption,
#wp-calendar tr,
#wp-calendar td,
#wp-calendar th {
  text-align: center;
}

#wp-calendar caption {
  text-transform: capitalize;
  color: #111;
  padding-bottom: 4%;
}

#wp-calendar th,
#wp-calendar td {
  padding: 4% 2%;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

#wp-calendar thead th {
  font-size: 0.75em;
  font-weight: 900;
  text-transform: uppercase;
  color: #ca2017;
}

#wp-calendar th:first-child,
#wp-calendar tbody td:first-child {
  border-left: 1px solid #ddd;
}

#wp-calendar tbody a:hover {
  text-decoration: underline;
}

#wp-calendar tfoot td {
  padding: 0;
  border: none !important;
}
#wp-calendar tfoot #prev a {
  text-align: left;
}
#wp-calendar tfoot #next a {
  text-align: right;
}

#wp-calendar tfoot a {
  display: block;
  margin-top: 12px;
  font-size: 0.75em;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #111;
}

#wp-calendar tfoot a:hover {
  text-decoration: none;
  color: #ca2017;
}

/* Lovecraft Widget List --------------------------------------- */

.lovecraft-widget-list a {
  display: block;
  min-height: 55px;
  position: relative;
}

.lovecraft-widget-list .post-icon {
  display: block;
  width: 55px;
  height: 55px;
  padding: 2px;
  background: #fff;
  border: 1px solid #eee;
  border-radius: 99px;
  position: absolute;
  top: 0;
  left: 0;
}

.lovecraft-widget-list .post-icon img {
  display: block;
  border-radius: 99px;
}

.lovecraft-widget-list .post-icon .genericon {
  position: absolute;
  top: 50%;
  margin-top: -8px;
  left: 50%;
  margin-left: -8px;
}

.lovecraft-widget-list .inner {
  padding-left: 65px;
}

.lovecraft-widget-list .title {
  font-family: "Playfair Display", Georgia, serif;
  line-height: 140%;
  font-weight: 700;
  color: #111;
}

.lovecraft-widget-list .meta {
  margin-top: 3px;
  font-size: 0.75em;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #888;
}

.lovecraft-widget-list .excerpt {
  margin-top: 3px;
  color: #666;
  line-height: 130%;
}

.lovecraft-widget-list a:hover .title {
  text-decoration: underline;
}

/* Flickr Widget --------------------------------------- */

.flickr_badge_image {
  display: inline-block;
  margin: 0 10px 10px 0;
}

.flickr_badge_image a,
.flickr_badge_image img {
  display: block;
}

.flickr_badge_image a {
  padding: 4px;
  background: #fff;
  border: 1px solid #eee;
}
