/* -------------------------------------------------------------------------------- */
/*	14. Credits
/* -------------------------------------------------------------------------------- */

.credits.section {
  padding: 30px 0;
}

.credits p {
  padding-bottom: 5px;
  font-family: "Lato", Helvetica, sans-serif;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.credits .sep {
  margin: 0 5px;
  color: #ca2017;
  font-family: "Playfair Display", Georgia, serif;
  font-size: 18px;
  font-style: italic;
  font-weight: 700;
  position: relative;
  bottom: -2px;
}

.credits p a {
  color: #fff;
}
.credits p a:hover {
  color: #ca2017;
}
